<template>
    <div class="auth-layout">
        <logo-outlined class="auth-layout__bg-logo" />

        <scroll-container>
            <div class="auth-layout__outer">
                <auth-header :with-menu="false" />

                <div class="auth-layout__notifications" ref="notifications" />

                <div class="auth-layout__inner">
                    <div class="auth-layout__content">
                        <img
                            class="auth-layout__img"
                            src="~@/assets/images/skins-factory.png"
                            alt="skins-factory"
                            data-depth-x="0.4"
                            data-depth-y="0.2"
                        />

                        <slot />
                    </div>

                    <auth-footer />
                </div>
            </div>
        </scroll-container>
    </div>
</template>

<script>
    import AuthFooter from '@/pages/layout/AuthFooter';
    import AuthHeader from '@/pages/layout/AuthHeader';
    import LogoOutlined from '@/components/icons/LogoOutlined';
    import ScrollContainer from '@/components/ScrollContainer';

    export default {
        name: 'AuthLayout',
        components: {
            AuthFooter,
            AuthHeader,
            LogoOutlined,
            ScrollContainer,
        },
        mounted() {
            this.$toast.updateDefaults({
                container: this.$refs.notifications,
            });
        },
    };
</script>

<style lang="scss" scoped>
    .auth-layout,
    .auth-layout__inner {
        display: flex;
        flex-direction: column;
    }

    .auth-layout {
        position: relative;
        align-items: center;
        justify-content: center;
        height: 100%;
        background-color: #050b1c;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 192rem;
            aspect-ratio: 16/9;
            background: linear-gradient(0deg, #050b1c, #050b1c),
                radial-gradient(41.41% 73.61% at 52.45% -4.26%, rgba(59, 72, 111, 0.2) 0%, rgba(14, 17, 26, 0.2) 100%);
            transform: translate(-50%, 0);
        }
    }

    .auth-layout__bg-logo {
        position: absolute;
        top: -22rem;
        width: 107rem;
        height: 107rem;

        ::v-deep path {
            stroke: rgba(188, 210, 228, 0.03);
        }
    }

    .auth-layout__outer {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .auth-layout__notifications {
        position: relative;
        width: 100%;
        z-index: 1;
    }

    .auth-layout__inner {
        flex-grow: 1;
        grid-gap: 4rem;
        width: 100%;
        max-width: 128rem;
        margin: 0 auto;
        padding: 4rem 1.5rem 1.5rem;
    }

    .auth-layout__content {
        position: relative;
        display: flex;
        align-items: center;
    }

    .auth-layout__img {
        display: none;
        position: absolute;
        top: -12rem;
        left: -12rem;
        width: 86rem;
        aspect-ratio: 1;
        z-index: -1;
    }

    .auth-footer {
        margin-top: auto;
    }

    ::v-deep {
        .scroll-container {
            position: relative;
            overflow: hidden;
        }

        .scroll-container__content {
            display: flex;
            flex-direction: column;
        }
    }

    @media screen and (min-width: 64em) {
        .auth-layout__notifications {
            position: absolute;
            left: 50%;
            top: 0;
            max-width: 128rem;
            transform: translateX(-50%);
        }

        .auth-layout__inner {
            grid-gap: 10rem;
            padding: 6rem 4rem 4rem;
        }

        .auth-layout__img {
            display: block;
        }
    }

    @media screen and (min-width: 82.5em) {
        .auth-layout__notifications {
            position: absolute;
            left: 0;
            max-width: unset;
            transform: unset;
        }
    }
</style>
