<template>
    <svg viewBox="0 0 1070 1070" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M643.056 259.436L740.727 426.702L70.0352 809.407V516.288C70.0295 477.384 80.384 439.163 100.059 405.468C119.734 371.772 148.037 343.789 182.123 324.331L405.878 196.609C488.94 149.201 595.14 177.342 643.056 259.436L643.92 258.932L643.056 259.436Z"
            stroke-width="2"
        />

        <path
            d="M887.817 763.064L664.218 890.65C581.134 938.058 474.905 909.913 426.976 827.811L331.183 662.657L999.97 277.858V571.098C999.967 610.008 989.601 648.233 969.915 681.929C950.228 715.626 921.914 743.609 887.817 763.064Z"
            stroke-width="2"
        />
    </svg>
</template>

<script>
    export default {
        name: 'LogoOutlined',
    };
</script>
